@import "./main.scss";

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html,
body {
  // overflow-x: hidden;
  // overflow-y: unset;
  // height: 100vh;
  // background-color: $background-pink;
  scroll-behavior: smooth;
  font-family: "neue-haas-grotesk-display";
  font-weight: 400;
  color: $text-black-2;
  background-color: $bg-grey-1;

  // @include responsive(normal) {
  //   overflow-x: unset;
  // }
}

html {
  // min-height: 100%;
  // height: auto;
  // font-size: 56% !important;
  font-size: 10px;
  width: 100%;
  font-size: 10px;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    display: none;
  }

  @include responsive(mac-13) {
    font-size: 62.5% !important;
  }
}

body {
  width: 100%;
  // overflow: hidden;
  font-size: 1.6rem;
  line-height: unset;

  @include responsive(tablet) {
    font-size: 2rem;
  }
}

a {
  color: unset;
  text-decoration: none;
  transition: 0.3s all;
  cursor: pointer;
  display: flex;
  align-items: center;

  &:hover {
    // color: $second;
  }
}

img {
  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.header-height {
  padding-top: 7.5rem;
}

.w-18 {
  width: 100%;

  @include responsive(mac-13) {
    width: calc(100% - 22rem);
    margin-left: 18rem;
  }

  @include responsive(mac-16) {
    width: calc(100% - 18rem);
  }
}

.w-22 {
  width: 100%;

  @include responsive(small) {
    margin: 0 auto;
    width: calc(100% - 22rem);
  }
}

.w-36 {
  width: 100%;

  @include responsive(small) {
    margin: 0 auto;
    width: calc(100% - 36rem);
  }
}

.w-44 {
  width: 100%;

  @include responsive(small) {
    margin: 0 auto;
    width: calc(100% - 44rem);
  }
}

.loading-screen {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .icon-logo-loading {
    font-size: 6rem;
  }
}

.text-hover {
  position: relative;
  cursor: pointer;

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 1px;
    bottom: 0;
    left: 0;
    background-color: $divider-black;
    transform-origin: bottom right;
    transition: transform 0.3s ease-out;
  }

  @include responsive(small) {
    &:hover {
      &::after {
        transform: scaleX(1);
        transform-origin: bottom left;
      }
    }
  }
}

.text-hover-white {
  position: relative;
  cursor: pointer;

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 1px;
    bottom: 0;
    left: 0;
    background-color: $cream-color;
    transform-origin: bottom right;
    transition: transform 0.3s ease-out;
  }

  @include responsive(small) {
    &:hover {
      &::after {
        transform: scaleX(1);
        transform-origin: bottom left;
      }
    }
  }
}

.text-hover-underline {
  position: relative;
  cursor: pointer;

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: 0;
    left: 0;
    background-color: $divider-black;
  }

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 1px;
    bottom: 0;
    left: 0;
    background-color: $divider-black;
    transition-delay: 0.5s;
    transform-origin: bottom right;
    transition: transform 0.3s ease-out;
  }

  @include responsive(small) {
    &:hover {
      &::before {
        display: none;
      }

      &::after {
        transform: scaleX(1);
        transform-origin: bottom left;
      }
    }
  }
}

.text-hover-underline-white {
  position: relative;
  cursor: pointer;

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: $text-white;
  }

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: $text-white;
    transition-delay: 0.5s;
    transform-origin: bottom right;
    transition: transform 0.3s ease-out;
  }

  @include responsive(small) {
    &:hover {
      &::before {
        display: none;
      }

      &::after {
        transform: scaleX(1);
        transform-origin: bottom left;
      }
    }
  }
}

.text-hover-underline-white-2 {
  position: relative;
  cursor: pointer;

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: $cream-color;
  }

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: $cream-color;
    transition-delay: 1s;
    transform-origin: bottom right;
    transition: transform 0.4s ease-out;
  }

  @include responsive(small) {
    &:hover {
      &::before {
        // display: none;
        width: 0;
      }

      &::after {
        transform: scaleX(1);
        transform-origin: bottom left;
      }
    }
  }
}

.text-hover-underline-white-black {
  position: relative;
  cursor: pointer;

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: $text-black-2;

    @include responsive(normal) {
      background-color: $text-white-2;
    }
  }

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: $text-black-2;
    transition-delay: 0.5s;
    transform-origin: bottom right;
    transition: transform 0.3s ease-out;

    @include responsive(normal) {
      background-color: $text-white-2;
    }
  }

  @include responsive(small) {
    &:hover {
      &::before {
        display: none;
      }

      &::after {
        transform: scaleX(1);
        transform-origin: bottom left;
      }
    }
  }
}

.footer-page {
  width: 100%;
  height: 80rem;
  background-image: url(../assets/images/home-screen/example-img-plan.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;

  @include responsive(tablet) {
    height: 88rem;
  }

  @include responsive(normal) {
    height: 90rem;
  }

  @include responsive(mac-16) {
    height: 100vh;
  }

  .footer-container {
    @include container-fluid;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
    z-index: 0;

    .text-content {
      position: absolute;
      font-size: 5.6rem;
      font-family: "garamond-premier-pro-display", serif;
      color: $text-white-2;

      @include responsive(tablet) {
        font-size: 8rem;
      }

      @include responsive(small) {
        font-size: 11.2rem;
      }

      &--text-1 {
        top: 8.6rem;
        left: 45%;
        transform: translateX(-50%);
      }

      &--text-2 {
        top: 20rem;
        left: 2.4rem;

        @include responsive(tablet) {
          left: 25rem;
          top: 30rem;
        }

        @include responsive(mac-16) {
          top: 25rem;
          left: 30rem;
        }
      }

      &--text-3 {
        bottom: 40rem;
        right: 2.4rem;

        @include responsive(tablet) {
          bottom: 35rem;
          right: 25rem;
        }

        @include responsive(mac-16) {
          right: 40rem;
        }
      }
    }

    .footer-menu {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: $space-6;
      color: $text-white-2;
      padding-bottom: 15rem;
      // font-size: 2.4rem;

      @include responsive(tablet) {
        // font-size: 2.8rem;
        gap: 15rem;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
      }

      @include responsive(normal) {
        justify-content: center;
      }

      .item-menu {
        text-transform: uppercase;
        width: max-content;
      }
    }
  }
}
