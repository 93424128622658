$bg-black: #1e1e1e;
$bg-white: #ffffff;
$bg-grey: #e0ded8;
$bg-grey-1: #f6f5f4;
$bg-header: #988f86;

$cream-color: #f8f6d7;
$brown-color: #817364;

$text-white: #ffffff;
$text-white-2: #f6f5f4;
$text-black: #000000;
$text-black-2: #1e1e1e;
$text-black-3: #272727;
$text-grey: #9b9b9b;
$text-grey-2: #e0ded8;
$text-grey-3: #666666;
$text-grey-4: #817f7e;
$text-grey-5: #e9e7e0;

$divider: #e0ded8;
$divider-grey: #cbcbcb;
$divider-grey-2: #887d73;
$divider-black: #1e1e1e;
$divider-black-2: #393939;
