@import "styles/main.scss";

.eventScreenWrapper {
  :global {
    .upcoming-title,
    .recent-title {
      font-size: 4.8rem;
      font-family: "garamond-premier-pro-display", serif;
      margin-bottom: $space-12;

      @include responsive(tablet) {
        margin-bottom: $space-24;
        font-size: 8rem;
      }
    }

    .upcoming-event {
      padding-top: 20.7rem;
      padding-bottom: 12.8rem;
      background-color: $bg-black;
      color: $text-white;

      .upcoming-container {
        @include container-fluid;

        .upcoming-body {
          margin-left: auto;

          @include responsive(normal) {
            width: calc(100% - 18rem);
          }
        }
      }
    }

    .recent-event {
      padding: 12.8rem 0;

      .recent-event-container {
        @include container-fluid;

        .recent-body {
          margin-left: auto;

          @include responsive(normal) {
            width: calc(100% - 18rem);
          }
        }
      }
    }

    .footer-page {
      background-image: url(../../assets/images/home-screen/footer-img-3.png);

      .footer-container {
        .text-content {
          &--text-2 {
            left: $space-8;

            @include responsive(normal) {
              left: 18rem;
            }
          }

          &--text-3 {
            right: $space-8;
            text-align: right;

            @include responsive(normal) {
              text-align: unset;
              right: 18rem;
            }
          }
        }
      }
    }
  }
}
