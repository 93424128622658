@import "styles/main.scss";

.footer-wrapper {
  width: 100%;
  position: relative;
  z-index: 1;
  padding: $space-12 0;
  color: $text-black-2;
  background-color: $bg-grey-1;

  &--hide {
    display: none;
  }

  &--wwc {
    background-color: $text-black;
    color: $cream-color !important;

    &::after {
      background-color: $text-black !important;
      display: none;
    }
  }

  @include responsive(tablet) {
    padding: 5.6rem 0;
  }

  @include responsive(normal) {
    padding: 8rem 0;
  }

  &::after {
    content: "";
    position: absolute;
    width: 120%;
    height: 15rem;
    background-color: $bg-grey-1;
    top: -8rem;
    left: 50%;
    transform: translate(-50%);
    border-radius: 50%;
    z-index: -1;

    @include responsive(small) {
      height: 30rem;
    }
  }

  .footer-container {
    @include container-fluid;
    display: flex;
    gap: $space-12;
    flex-direction: column;
    justify-content: space-between;

    @include responsive(normal) {
      gap: $space-6;
      flex-direction: row;
    }

    .footer-left,
    .footer-right {
      width: 100%;

      // @include responsive(normal) {
      //   width: 50%;
      // }
    }

    .footer-left {
      display: flex;
      gap: $space-10 $space-6;
      justify-content: space-between;
      flex-wrap: wrap;

      @include responsive(normal) {
        gap: $space-6;
        // justify-content: unset;
      }

      .menus {
        min-width: calc(100% / 2 - 2.4rem);
        font-size: 1.6rem;

        @include responsive(tablet) {
          min-width: calc(100% / 4 - 2.4rem);
          justify-content: unset;
        }

        &--hide {
          display: none;
        }

        .menu-title {
          margin-bottom: $space-3;
          text-transform: uppercase;
        }

        .menu-list {
          .item-menu {
            margin-bottom: $space-2;

            &--hide {
              display: none;
            }

            &--coming {
              pointer-events: none;
            }

            &:last-child {
              margin-bottom: 0;
            }

            .item-menu__text {
              width: max-content;
            }
          }
        }
      }
    }

    .footer-right {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .head-social {
        display: flex;
        justify-content: space-between;
        // flex-direction: row-reverse;
        gap: $space-6;
        flex-wrap: wrap;

        .item-social {
          width: calc(100% / 2 - 2.4rem);
          font-size: 1.6rem;
          text-transform: uppercase;
          display: flex;

          @include responsive(tablet) {
            width: calc(100% / 4 - 2.4rem);
          }

          @include responsive(normal) {
            justify-content: flex-end;
          }

          &--box {
            width: 100%;
          }

          &--hide {
            display: none;
          }

          .item-social-link {
          }
        }
      }
    }
  }

  .ft-mobile-wrapper {
    @include container-fluid;
  }

  .head-login {
    width: 100%;
    gap: $space-3;
    border-bottom: 1px solid $divider-black;

    &--desktop {
      display: none;

      @include responsive(normal) {
        display: flex;
        padding-bottom: $space-6 !important;
      }
    }

    &--mobile {
      display: flex;
      margin-bottom: $space-12 !important;
      padding-bottom: $space-6 !important;

      @include responsive(normal) {
        display: none;
      }
    }

    .input-content {
      width: 100%;
      border-color: transparent;
      font-size: 2rem;
      font-weight: 400;
      padding: 0;
      outline: none;
      border: none;
      background-color: transparent;

      &::placeholder {
        color: $text-black;
      }

      &::-webkit-input-placeholder {
        font-weight: normal;
      }
    }

    .button {
      width: max-content;
      white-space: nowrap;
      font-size: 2rem;
      cursor: pointer;
    }
  }

  .text-img {
    @include container-fluid;

    .viction-text {
      width: 100%;
      margin-top: 5rem !important;
      opacity: 0.3;
    }
  }
}
