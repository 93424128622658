@import "styles/main.scss";

.victionDAWrapper {
  background-color: $bg-white;
  color: #272727;
  position: relative;

  :global {
    h1 {
      font-family: "Viction";
      font-size: 4.8rem !important;
      line-height: 0.9;

      @include responsive(normal) {
        font-size: 8.4rem !important;
      }
    }

    .viction-da-container {
      .da-banner {
        height: 100vh;
        background-color: $text-black;
        color: $cream-color;
        position: relative;
        z-index: 0;
        overflow: hidden;

        @include responsive(normal) {
          height: 100rem;
        }

        .pattern-up {
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          width: 100%;
        }

        .video-box {
          position: absolute;
          z-index: -1;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 350%;

          @include responsive(normal) {
            width: 100%;
          }

          &::after {
            content: "";
            position: absolute;
            width: 100%;
            height: 70%;
            background: linear-gradient(to bottom, $text-black, rgba($text-black, 0) 70%);
            top: -5px;
            left: 50%;
            transform: translateX(-50%);
          }

          &::before {
            content: "";
            position: absolute;
            width: 100%;
            height: 70%;
            background: linear-gradient(to top, $text-black, rgba($text-black, 0) 70%);
            bottom: -5px;
            left: 50%;
            transform: translateX(-50%);
          }

          .video {
            width: 100%;
            height: 100%;
          }
        }

        .da-banner-container {
          @include container-2;
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: center;
          height: 100%;

          .banner-title {
            text-align: center;

            @include responsive(normal) {
              width: 60rem;
            }
          }

          .banner-description {
            text-align: center;
            margin: $space-3 0 5.6rem;
            color: #817f7e;
            font-size: 1.6rem;

            @include responsive(normal) {
              width: 60rem;
              font-size: unset;
            }
          }

          .button-actions {
            display: flex;
            align-items: center;
            // flex-direction: column;
            gap: $space-4;

            @include responsive(normal) {
              gap: $space-3;
            }

            .btn {
              padding: $space-3 $space-4;
              cursor: pointer;
              background-color: $bg-white;
              color: $text-black-2;
              transition: 0.3s all;
              display: flex;
              align-items: center;
              gap: $space-2;
              white-space: nowrap;
              font-weight: bold;
              border-radius: $radius;

              @include responsive(normal) {
                padding: $space-4 $space-5;
              }

              &:hover {
                background-color: $bg-black;
                color: $text-white;
              }
            }
          }
        }
      }

      .your-rollup {
        padding: 14rem 0;
        min-height: 100vh;

        .your-rollup-container {
          @include container-2;

          .your-rollup-text {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;

            .rollup-title {
              @include responsive(normal) {
                // width: 70rem;
              }
            }

            .rollup-description {
              margin-top: $space-3;
              font-size: 1.8rem;

              @include responsive(normal) {
                margin-top: $space-6;
                width: 45rem;
                font-size: unset;
              }
            }
          }

          .your-rollup-content {
            display: grid;
            grid-template-columns: 1fr;
            margin-top: $space-20;

            @include responsive(normal) {
              gap: $space-5;
              grid-template-columns: repeat(3, 1fr);
            }

            .item-rollup {
              padding: $space-4 0;
              border-top: 1px solid $text-black;

              @include responsive(normal) {
                padding: $space-8;
              }

              &--circle-img {
                padding: 0;
                border-top: 1px solid transparent;
                display: none;

                @include responsive(normal) {
                  display: block;
                }
              }

              .item-rollup__title {
                text-transform: uppercase;
                font-size: 1.8rem;

                @include responsive(normal) {
                  font-size: 2.8rem;
                }
              }

              .item-img {
                margin: $space-10 auto;
                display: flex;
                justify-content: center;

                @include responsive(normal) {
                  padding: $space-8;
                }

                .img-item {
                  width: 12rem;
                }
              }

              .item-content {
                color: $text-grey-4;
              }

              .img-circle {
                width: 100%;
              }
            }
          }
        }
      }

      .the-block {
        background-color: #7c726a;

        .the-block-container {
          @include container-2;
          display: grid;
          grid-template-columns: 1fr;

          @include responsive(normal) {
            gap: $space-12;
            grid-template-columns: 1fr 0.9fr;
          }

          .block-left {
            width: 100%;
            display: flex;
            height: 100%;
            flex-direction: column;
            justify-content: space-between;
            color: $text-grey-5;
            grid-row: 2;
            padding: 4rem 0;

            @include responsive(normal) {
              grid-row: unset;
              padding: 14rem 0;
            }

            .block-left__title {
              margin-bottom: $space-5 !important;
              line-height: 0.9;
            }

            .block-left__content {
              font-size: 1.8rem;
              width: 95%;

              @include responsive(normal) {
                font-size: 2.4rem;
              }
            }
          }

          .block-right {
            width: 100%;
            height: 25rem;
            overflow: hidden;
            display: flex;
            align-items: center;

            @include responsive(normal) {
              height: unset;
            }

            .block-right__img {
              width: 100%;
            }
          }
        }
      }

      .the-future {
        padding: $space-20 0;

        @include responsive(normal) {
          padding: 14rem 0;
        }

        .the-future-container {
          @include container-2;

          .future-text {
            margin-bottom: 6rem;
            position: sticky;
            top: 5rem;
            background-color: $bg-white;

            @include responsive(normal) {
              position: unset;
              top: unset;
              margin-bottom: 12rem;
            }

            .future-text__title {
              margin-bottom: $space-4 !important;
            }

            .future-text__description {
              font-size: 1.6rem;

              @include responsive(normal) {
                font-size: 2.4rem;
                width: 80rem;
              }
            }
          }

          .future-content {
            display: grid;
            grid-template-columns: 1fr;

            @include responsive(normal) {
              gap: $space-16;
              grid-template-columns: repeat(3, 1fr);
            }

            .item-future {
              padding: $space-6 0;
              border-top: 1px solid $text-black;
              position: sticky;
              top: 35rem;
              background-color: $bg-white;

              @include responsive(normal) {
                position: unset;
                top: unset;
              }

              .item-future__title {
                font-size: 1.8rem;
                text-transform: uppercase;
                margin-bottom: 3.6rem;

                @include responsive(normal) {
                  font-size: 2.4rem;
                }
              }

              .item-future__content {
                color: $text-grey-4;
              }
            }
          }
        }
      }

      .get-started {
        height: 90rem;
        // background-color: $text-black;
        color: $cream-color;
        padding-top: 14rem;
        position: relative;
        z-index: 0;
        position: relative;
        z-index: 0;
        overflow: hidden;

        .bg-black {
          position: absolute;
          z-index: -1;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
          width: 100vh;
          height: 100%;

          @include responsive(normal) {
            // height: unset;
            width: 110vw;
          }
        }

        .circle-dot-4 {
          position: absolute;
          bottom: 15rem;
          left: 50%;
          transform: translateX(-50%);
          width: 200%;

          @include responsive(normal) {
            width: 100%;
            bottom: 0;
          }
        }

        .get-started-container {
          @include container-2;

          .started-text {
            display: flex;
            align-items: center;
            flex-direction: column;

            .started-description {
              font-size: 2rem;
              color: #988f86;
              text-align: center;
              margin-top: $space-5;

              @include responsive(normal) {
                width: 50rem;
                font-size: 2.4rem;
              }
            }
          }

          .started-links {
            margin-top: $space-20;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            font-size: 2.4rem;
            gap: 2rem;

            @include responsive(normal) {
              font-size: 2rem;
              gap: 8.3rem;
              flex-direction: row;
            }
          }
        }
      }
    }
  }
}
