@import "styles/main.scss";

.hubWrapper {
  :global {
    .hub-container {
      .hub-banner-wrapper {
        padding-top: 20.8rem;
        padding-bottom: 12.8rem;
        // background-color: $cream-color;

        .hub-banner {
          @include container-fluid;

          .hub-banner__title {
            // font-size: 8rem;
            font-family: "garamond-premier-pro-display", serif;
            margin-bottom: $space-8 !important;

            @include responsive(normal) {
              width: 50%;
            }
          }

          .hub-banner__description {
            display: flex;
            gap: 5.6rem;
            flex-direction: column;

            @include responsive(tablet) {
              margin-bottom: $space-24;
            }

            @include responsive(normal) {
              gap: $space-6;
              align-items: flex-end;
              flex-direction: row;
            }

            .description-item {
              @include responsive(normal) {
                width: 50%;
              }

              &--link {
                transform: translateX(10rem);

                @include responsive(normal) {
                  transform: translateX(18rem);
                }
              }

              .description-text {
                width: max-content;
                text-transform: uppercase;
                // font-size: 2.8rem;
              }
            }

            .description-text-custom {
              margin-bottom: 0 !important;

              .text-content {
                font-size: 3.2rem;
              }
            }
          }

          .hub-banner__img {
            width: 100%;
            justify-content: center;
            position: relative;
            display: none;

            @include responsive(tablet) {
              display: flex;
            }

            .banner-img {
              width: 100%;

              @include responsive(normal) {
                width: calc(100% - 18rem * 2);
              }
            }

            .text-img {
              position: absolute;
              top: 50%;
              left: 50%;
              color: $text-white;
              transform: translate(-50%, -50%);
              // font-size: 7.2rem;
              font-family: "garamond-premier-pro-display", serif;
              width: 100%;
              padding: 0 7.2rem;

              @include responsive(normal) {
                padding: 0;
                left: 26rem;
                width: unset;
                transform: translateY(-50%);
              }
            }
          }
        }
      }

      .hub-program-event-wrapper {
        background-color: $bg-black;
        padding: 12.8rem 0;

        .hub-program-event {
          @include container-fluid;
          color: $text-white-2;

          .program-event {
            margin-bottom: $space-24;

            &--no-margin {
              margin-bottom: 0;
            }

            .program-event__title {
              font-size: 4.8rem;
              font-family: "garamond-premier-pro-display", serif;
              margin-bottom: $space-6;

              @include responsive(tablet) {
                font-size: 8rem;
              }
            }

            .program-event__description {
              // font-size: 3.2rem;

              @include responsive(tablet) {
                text-indent: 18rem;
              }
            }

            .program-event__body {
              display: flex;
              margin-top: 7.2rem;
              gap: $space-6;
              width: 100%;

              @include responsive(normal) {
                margin-left: 18rem;
                width: calc(100% - 22rem);
              }

              .item-program {
                width: 100%;

                .program-img {
                  width: 100%;
                  aspect-ratio: 1/1;
                }
              }
            }

            .program-event__community {
              display: grid;
              grid-template-columns: repeat(5, 1fr);
              gap: $space-6;
              width: calc(100% - 18rem * 2);
              // margin-top: 7.2rem;
              margin: 7.2rem auto 0;

              .item-community {
                width: 100%;

                .community-img {
                  width: 100%;
                  height: 100%;
                }
              }
            }
          }
        }
      }

      .footer-page {
        background-image: url(../../assets/images/home-screen/footer-img-3.png);

        .text-content {
          &--text-2 {
            left: $space-8;

            @include responsive(normal) {
              left: 18rem;
            }
          }

          &--text-3 {
            right: $space-8;
            text-align: right;

            @include responsive(normal) {
              text-align: unset;
              right: 18rem;
            }
          }
        }
      }
    }
  }
}
