@import "styles/main.scss";

.notFoundWrapper {
  width: 100%;
  // height: 100vh;
  height: calc(100vh - var(--height-ft));
  display: flex;
  align-items: center;
  justify-content: center;

  :global {
    h3 {
      transform: translateY(8rem);
    }
  }
}
