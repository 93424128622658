@import "styles/main.scss";

.header-wallet-wrapper {
  width: 100%;
  position: fixed;
  top: 0;
  padding: $space-3 0;
  z-index: 888;
  transition: 0.2s all;
  color: $text-black-2;
  background-color: $bg-black;

  &--change-background {
    // box-shadow: 0px 5px 10px rgba($bg-black, 0.2);
  }

  &--sub-open {
    background-color: $bg-header;
  }

  .header-container {
    @include container-fluid;

    @include responsive(normal) {
      min-height: 5rem;
    }

    .header-menu-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      // position: relative;
      min-height: 5rem;
      z-index: 0;

      @include responsive(normal) {
      }

      .icon-logo {
        font-size: 3.2rem;
        color: $cream-color;

        @include responsive(tablet) {
          font-size: 4rem;
        }

        @include responsive(mac-13) {
          font-size: 4rem;
        }

        &--text-change {
          color: $cream-color;
        }

        &--menu-home {
          color: $text-black-2;
        }
      }

      .icon-menu {
        font-size: 3.2rem;
        color: $cream-color;

        @include responsive(tablet) {
          font-size: 4rem;
        }

        @include responsive(normal) {
          display: none;
        }

        &--text-change {
          color: $cream-color;
        }

        &--menu-color {
          color: $text-black-2;
        }
      }

      .header-link {
        display: flex;
        gap: $space-6;
        flex-direction: column;
        position: absolute;
        top: 7rem;
        left: 100%;
        width: 100%;
        max-height: 100rem;
        padding: $space-8 $space-6 $space-5;
        transition: 0.3s all;
        color: $cream-color;

        @include responsive(tablet) {
          padding: $space-8 $space-8 $space-5;
        }

        @include responsive(normal) {
          background-color: transparent;
          flex-direction: row;
          align-items: center;
          padding-top: 0;
          padding-bottom: 0;
          gap: $space-20;
          left: 0;
          height: unset;
          position: unset;
          width: unset;
        }

        &--show {
          background-color: $bg-header;
          left: 0;
        }

        &--text-change {
          color: $cream-color;
        }

        &--menu-home {
          color: $text-black-2;
        }

        .item-link {
          font-size: 1.6rem;

          &--back {
            display: flex;
            align-items: center;
            gap: $space-3;
          }

          &--line {
            pointer-events: none;
            height: 1px;
            width: 100%;
            background-color: $divider-grey-2;

            @include responsive(normal) {
              width: unset;
              height: unset;
              background-color: transparent;
            }

            .text-content {
              display: none;

              @include responsive(normal) {
                display: block;
              }
            }
          }
        }
      }

      .icon-close {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
        font-size: 2.4rem;
        cursor: pointer;
        transition: 0.3s all;

        &:hover {
          color: $cream-color;
        }
      }
    }
  }
}
