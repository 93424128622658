@import "styles/main.scss";

.homeScreenWrapper {
  :global {
    .home-banner {
      height: 90vh;
      width: 100%;
      // background-color: $brown-color;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      padding-bottom: $space-12;
      position: relative;
      z-index: 0;

      @include responsive(tablet) {
        height: 100vh;
      }

      @include responsive(normal) {
        padding-bottom: 9.6rem;
        height: 90rem;
      }

      @include responsive(mac-16) {
        height: 100vh;
      }

      .banner-container {
        @include container-fluid;
        display: grid;
        grid-template-columns: 1fr;

        .banner-text {
          width: 100%;

          .banner-text-container {
            @include responsive(tablet-2) {
              width: 99rem;
            }

            .title-custom {
              margin-bottom: $space-16;
            }

            .box-link {
              gap: $space-8;
              display: flex;
              flex-direction: column;
              font-size: 2.4rem;
              color: $text-white-2;
              // justify-content: center;

              @include responsive(tablet) {
                justify-content: space-between;
                flex-direction: row;
                font-size: 2.8rem;
              }

              @include responsive(tablet-2) {
                gap: 15rem;
              }

              @include responsive(normal) {
                justify-content: unset;
              }

              .item-link {
                white-space: nowrap;
                text-transform: uppercase;
                width: max-content;
              }
            }
          }
        }
      }

      .slide-banner {
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;

        .img-slide {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .custom-slide-banner {
          width: 100% !important;
          height: 100% !important;

          .slick-list {
            width: 100% !important;
            height: 100% !important;

            .slick-track {
              // width: 100% !important;
              height: 100% !important;

              .slick-slide > div {
                // width: 100% !important;
                height: 100% !important;
              }

              .img-slide {
                // width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }
          }
        }
      }
    }
    // }

    .home-content-wrapper {
      @include container-fluid;
      padding-top: 12.8rem !important;

      @include responsive(tablet) {
        padding-top: 15.6rem !important;
      }

      .home-content-title {
        width: 100%;
        margin-bottom: $space-12;

        @include responsive(normal) {
          width: 70%;
        }

        @include responsive(mac-16) {
          width: 60%;
        }

        &--section-slide {
          // margin-bottom: 9.6rem;
        }
      }

      .img-box {
        display: flex;
        justify-content: center;
        margin-bottom: 12.8rem;

        @include responsive(small) {
          margin-bottom: 14.4rem;
          justify-content: flex-end;
        }

        .img-content {
          width: 180%;
          aspect-ratio: 16/9;
          object-fit: cover;

          @include responsive(tablet) {
            width: 100%;
          }

          @include responsive(normal) {
            width: calc(100% - 18rem);
          }
        }
      }

      .body-content-slide {
        display: grid;
        grid-template-columns: 1fr;
        gap: 4rem $space-3;
        margin-bottom: 12.8rem;

        @include responsive(tablet) {
          grid-template-columns: 0.6fr 1fr;
        }

        @include responsive(normal) {
          gap: $space-6;
          margin-bottom: 16rem;
          grid-template-columns: 1fr 1fr;
          max-height: 85rem;
        }

        @include responsive(mac-16) {
          width: calc(100% - 18rem * 2);
          // margin-left: 18rem * 2;
          margin: 0 auto 12.8rem;
        }

        .text-slide {
          flex-direction: column;
          align-items: flex-end;
          display: none;

          @include responsive(tablet) {
            display: flex;
          }

          .item-slide-wrapper {
            margin-bottom: $space-6;
            width: 100%;
            cursor: pointer;
            display: flex;
            justify-content: flex-end;

            @include responsive(normal) {
              margin-bottom: 6rem;

              &:hover {
                .item-slide {
                  width: 90%;

                  &--active {
                    width: 80%;
                    pointer-events: none;
                  }
                }
              }

              &--active {
                pointer-events: none;
              }
            }

            .item-slide {
              border-bottom: 1px solid $divider;
              position: relative;
              width: 100%;
              z-index: 0;
              transition: 0.3s all linear;
              display: none;

              @include responsive(tablet) {
                display: block;
              }

              &--active {
                cursor: default;

                @include responsive(normal) {
                  width: 80%;
                }

                &::after {
                  width: 100% !important;
                  transition: 5s all linear;
                }
              }

              &::after {
                content: "";
                position: absolute;
                bottom: -1px;
                left: 0;
                width: 0%;
                height: 1px;
                background-color: $bg-black;
              }

              &:last-child {
                margin-bottom: 0;
              }

              .item-slide__title {
                color: $text-grey;
                font-size: 1.6rem;
                text-transform: uppercase;
              }

              .item-slide__value {
                font-size: 5.6rem;

                @include responsive(tablet) {
                  font-size: 6.4rem;
                }

                @include responsive(normal) {
                  font-size: 8rem;
                }
              }
            }
          }
        }

        .slide-body {
          min-width: 100%;
          display: none;

          @include responsive(tablet) {
            display: flex;
          }

          @include responsive(normal) {
            width: 80rem;
            max-height: 80rem;
          }

          .custom-slide {
            width: 100%;
            height: 100%;
            display: none;

            @include responsive(tablet) {
              display: block;
            }

            .slick-list {
              height: 100%;

              .slick-track {
                height: 100%;
              }

              .slick-slide {
                height: 100%;
              }
            }

            // .slick-list,
            // .slick-track,
            // .slick-slide,
            // .slick-slide > div,
            // .item-slide {
            //   width: 100%;
            //   height: 100%;
            // }

            .img-slide {
              width: 100%;
              // height: 85rem;
              -o-object-fit: cover;
              object-fit: cover;
              aspect-ratio: 1/1;
            }
          }
        }

        .item-content-status {
          width: 100%;

          @include responsive(tablet) {
            display: none;
          }

          .image-status {
            width: 100%;
            aspect-ratio: 1/1;
            object-fit: cover;
            margin-bottom: $space-3;
          }

          .text-status {
            .text-status__title {
              font-size: 1.4rem;
              color: $text-grey-3;
            }

            .text-status__value {
              font-size: 4rem;
            }
          }
        }
      }

      .box-video {
        width: 100%;
        position: relative;
        z-index: 0;
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-bottom: 12rem;
        aspect-ratio: 1/1;
        gap: $space-8;

        @include responsive(normal) {
          gap: 0;
          // height: 100vh;
          flex-direction: row;
          aspect-ratio: 16/9;
          align-items: flex-end;
          overflow: hidden;

          &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(to top, rgba($text-black, 0.7) 5%, rgba($text-black, 0) 35%);
            z-index: -1;
          }
        }

        .box-video__content {
          width: calc(100% + 3.2rem * 2);
          height: calc(100% + 3.2rem * 2);
          left: 0;
          top: 0;
          object-fit: cover;
          // position: unset;

          @include responsive(normal) {
            width: 100%;
            height: unset;
            z-index: -2;
            position: absolute;
          }
        }

        .box-video__text-wrapper {
          display: grid;
          grid-template-columns: 1fr;

          @include responsive(normal) {
            grid-template-columns: 1fr 0.8fr;
          }

          .text-item {
            align-items: center;

            @include responsive(normal) {
              padding-left: 7.7rem;
              grid-template-columns: 1fr 0.5fr;
            }

            &--menu-list {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: unset;
              padding-bottom: 4rem;

              @include responsive(tablet) {
                padding-left: 22rem;
              }

              @include responsive(normal) {
                grid-template-columns: 1fr 0.5fr;
              }
            }

            .text-head {
              text-indent: 10rem;
              margin-bottom: $space-8 !important;

              @include responsive(normal) {
                margin-bottom: $space-16 !important;
                text-indent: 18rem;
              }

              @include responsive(normal) {
                text-indent: 22rem;
              }
            }

            .text-video-custom {
              @include responsive(normal) {
                .text-title {
                  color: $text-grey-2;
                }

                .text-content {
                  color: $text-white-2;
                }
              }
            }

            .menu-item {
              margin-bottom: $space-3;
              cursor: pointer;
              transition: 0.3s all;
              width: 100%;
              // font-size: 2.4rem;

              @include responsive(normal) {
                color: $text-white-2;
                // font-size: 2.8rem;
              }

              &:last-child {
                margin-bottom: 0;
              }

              // &:hover {
              //   padding-left: 3rem;
              //   // transform: translateX(3rem);
              // }

              .menu-item__text {
                width: max-content;
                // border-bottom: 1px solid $text-black;

                // @include responsive(normal) {
                //   border-bottom-color: $text-white;
                // }
              }
            }
          }
        }
      }

      .viction-project {
        margin-bottom: $space-24;
        display: flex;
        flex-direction: column;
        gap: $space-10;

        @include responsive(mac-16) {
          align-items: flex-end;
          flex-direction: row;
          gap: 0;
        }

        .viction-project__text {
          @include responsive(normal) {
            width: 80%;
          }

          @include responsive(mac-13) {
            width: 60%;
          }

          @include responsive(mac-16) {
            width: 55%;
          }

          .text-custom {
            margin-bottom: 0 !important;
          }
        }

        .viction-project__link {
          @include responsive(tablet) {
            padding-left: 18rem;
          }

          @include responsive(mac-16) {
            width: 45%;
            display: flex;
            padding-left: 0;
            align-items: center;
            justify-content: center;
          }

          .text-link {
            // font-size: 2.4rem;
            width: max-content;
            text-transform: uppercase;

            @include responsive(tablet) {
              // font-size: 2.8rem;
            }
          }
        }
      }
    }

    .viction-project-list {
      max-width: 1920px;
      display: flex;
      // grid-template-columns: repeat(3, 1fr);
      gap: $space-6;
      margin: 0 auto;
      padding-right: $space-6;
      padding-left: $space-6;
      overflow: auto;
      padding-bottom: 13rem;

      @include responsive(normal) {
        overflow: hidden;
      }

      &::-webkit-scrollbar {
        display: none;
      }

      @include responsive(tablet) {
        padding-bottom: 0;
        padding-left: $space-6;
        padding-right: $space-6;
      }

      @include responsive(normal) {
        padding-right: $space-12;
        padding-left: 22.8rem;
      }

      .item-wrapper {
        min-width: 28rem;

        @include responsive(tablet) {
          min-width: 40rem;
        }
      }
    }

    .box-direction {
      @include container-fluid;
      display: flex;
      gap: $space-10;
      margin-top: 1rem !important;
      padding-bottom: 13rem !important;
      padding-left: $space-6;

      @include responsive(tablet) {
        padding-left: $space-6;
        margin-top: $space-12 !important;
      }

      @include responsive(normal) {
        align-items: center;
        padding-left: 22.8rem;
      }

      &--no-mobile {
        display: none;

        @include responsive(tablet) {
          display: flex;
        }
      }

      &--mobile {
        justify-content: center;

        @include responsive(tablet) {
          display: none;
        }
      }

      .icon-arrow {
        font-size: 4rem;
        cursor: pointer;
        transition: 0.3s all;

        @include responsive(normal) {
          &:hover {
            transform: scale(1.2);
          }
        }

        &--left-disable,
        &--right-disable {
          opacity: 0.4;
          pointer-events: none;
        }
      }
    }

    .victon-footer-page {
      width: 100%;
      height: 80rem;
      background-image: url(../../assets/images/home-screen/footer-img-1.png);
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;

      @include responsive(tablet) {
        height: 88rem;
      }

      @include responsive(normal) {
        height: 90rem;
      }

      @include responsive(mac-16) {
        height: 100vh;
      }

      .footer-container {
        @include container-fluid;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        position: relative;
        z-index: 0;

        .text-content {
          position: absolute;
          font-size: 5.6rem;
          font-family: "garamond-premier-pro-display", serif;
          color: $text-white-2;

          @include responsive(tablet) {
            font-size: 8rem;
          }

          @include responsive(small) {
            font-size: 11.2rem;
          }

          &--text-1 {
            top: 8.6rem;
            left: 45%;
            transform: translateX(-50%);
          }

          &--text-2 {
            top: 20rem;
            left: 2.4rem;

            @include responsive(tablet) {
              top: 30rem;
            }

            @include responsive(mac-16) {
              top: 25rem;
              left: 30rem;
            }
          }

          &--text-3 {
            bottom: 40rem;
            right: 2.4rem;

            @include responsive(tablet) {
              bottom: 35rem;
            }

            @include responsive(mac-16) {
              right: 40rem;
            }
          }
        }

        .footer-menu {
          display: flex;
          flex-direction: column;
          justify-content: center;
          gap: $space-6;
          color: $text-white;
          padding-bottom: 15rem;

          @include responsive(tablet) {
            gap: 15rem;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
          }

          @include responsive(normal) {
            justify-content: center;
          }

          .item-menu {
            text-transform: uppercase;
            width: max-content;
          }
        }
      }
    }
  }
}
