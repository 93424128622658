@import "styles/main.scss";

.text-head {
  font-size: 2.4rem;
  text-indent: 0;
  position: relative;
  z-index: 0;
  font-family: "garamond-premier-pro-display", serif;
  margin-bottom: $space-12;
  color: $text-white-2;

  @include responsive(tablet) {
    margin-bottom: $space-16;
    text-indent: 22rem;
    font-size: 4.8rem;
  }

  @include responsive(tablet-2) {
    font-size: 5.6rem;
  }

  @include responsive(normal) {
    margin-bottom: $space-10 + 0.4rem;
  }

  &:last-child {
    margin-bottom: $space-16;
  }

  &--small {
    text-indent: 10rem;

    @include responsive(tablet-2) {
      text-indent: 18rem;
    }
  }

  .text-title {
    margin-bottom: $space-6 !important;
    color: $text-black;
    text-indent: 0;
  }

  .text-content {
    font-size: 3.2rem;

    @include responsive(tablet) {
      font-size: 4.8rem;
    }

    @include responsive(normal) {
      font-size: 5.6rem;
    }
  }

  .text-sub {
    text-indent: 0;
    font-size: 1.6rem;
    top: 1.5rem;
    font-family: "neue-haas-grotesk-display";
    margin-bottom: $space-2;

    @include responsive(tablet) {
      position: absolute;
      margin-bottom: 0;
      font-size: 2rem;
      top: 1.8rem;
    }
  }

  &--reserve {
    font-family: "neue-haas-grotesk-display";
    color: $text-black;
    margin-bottom: $space-12 !important;
    text-indent: 10rem;

    @include responsive(tablet) {
      margin-bottom: $space-16 !important;
      text-indent: 22rem;
    }

    @include responsive(normal) {
      margin-bottom: 9.6rem !important;
    }

    .text-sub,
    .text-title {
      font-family: "garamond-premier-pro-display", serif;
    }

    .text-content {
      font-size: 2rem;
      color: $text-black-2;

      @include responsive(tablet) {
        font-size: 2.4rem;
      }

      @include responsive(normal) {
        font-size: 3.2rem;
      }

      // @include responsive(normal) {
      //   font-size: 4rem !important;
      // }
    }
  }
}
