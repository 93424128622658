@import "styles/main.scss";

.itemProjectWrapper {
  :global {
    .item-project {
      padding: $space-4;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      // max-height: 50rem;
      background-color: $bg-white;
      aspect-ratio: 1/1;
      width: 100%;
      cursor: pointer;
      border: 1px solid transparent;
      transition: 0.3s all;
      // max-width: 25rem;

      @include responsive(tablet) {
        padding: $space-6;
        max-width: unset;
        width: 100%;
      }

      @include responsive(normal) {
        &:hover {
          border-color: $divider-black;
        }
      }

      .icon-logo {
        width: 7.2rem;

        @include responsive(tablet) {
          width: 14rem;
        }
      }

      .item-name {
        font-size: 2rem;

        @include responsive(tablet) {
          font-size: 2.8rem;
        }

        @include responsive(normal) {
          font-size: 3.2rem;
        }
      }

      .description-info {
        padding: $space-8;
        padding-top: 0;
        font-size: 2.4rem;
      }

      &--small {
        .icon-logo {
          width: 4rem;

          @include responsive(tablet) {
            width: 7.2rem;
          }
        }

        .item-name {
          font-size: 1.8rem;

          @include responsive(tablet) {
            font-size: 2.4rem;
          }

          // @include responsive(normal) {
          // font-size: 2.4rem;
          // }
        }
      }
    }
  }
}
