@import "styles/main.scss";

.networkWrapper {
  width: 100%;
  overflow: hidden;

  :global {
    .network-container {
      @include container-fluid;
      display: grid;
      grid-template-columns: 1fr;
      gap: $space-6;
      margin-bottom: 12.8rem;
      padding-top: $space-10 !important;

      @include responsive(normal) {
        margin-bottom: 17.6rem;
        max-height: 100rem;
        grid-template-columns: 1fr 0.8fr;
      }

      .network-left {
        width: 100%;
        // padding-top: $space-10;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .text-title {
          // font-size: 5.6rem;
          margin-bottom: $space-12 !important;
          font-family: "garamond-premier-pro-display", serif;

          @include responsive(tablet) {
            // font-size: 8rem;
          }

          @include responsive(mac-13) {
            width: 70%;
            // font-size: 9.6rem;
          }

          @include responsive(mac-16) {
            width: 80%;
            // font-size: 9.6rem;
          }
        }

        .text-description {
          .custom-title {
            .text-content {
              font-size: 2rem !important;

              @include responsive(tablet) {
                font-size: 3.2rem !important;
              }
            }
          }
        }

        .link-reward {
          display: flex;
          gap: $space-6;
          // font-size: 2.4rem;
          flex-direction: column;
          padding-bottom: $space-12;

          @include responsive(tablet) {
            // font-size: 2.8rem;
            margin-left: 18rem;
          }

          @include responsive(mac-16) {
            flex-direction: row;
            margin-left: 18rem;
            gap: 11.3rem;
          }

          .text-link {
            width: max-content;
            white-space: nowrap;
          }
        }
      }

      .network-right {
        .img-box {
          width: 100%;
          aspect-ratio: 1/1;

          .img-banner {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .box-value {
          padding: $space-8 $space-12;
          background-color: $bg-grey;
          display: flex;
          flex-direction: column;
          gap: $space-6;

          @include responsive(tablet) {
            padding: $space-8 7.7rem;
          }

          @include responsive(normal) {
            padding: $space-12 7rem;
          }

          @include responsive(mac-16) {
            flex-direction: row;
          }

          .item-value {
            width: 100%;

            .item-value__title {
              font-size: 1.4rem;
              text-transform: uppercase;
              white-space: nowrap;

              @include responsive(tablet) {
                font-size: 1.6rem;
              }
            }

            .item-value__value {
              font-size: 5.6rem;
              color: $text-black-2;

              @include responsive(tablet) {
                font-size: 8rem;
              }
            }
          }
        }
      }
    }

    .hearth-viction-wrapper {
      @include container-fluid;
      margin-bottom: 12.8rem !important;
      display: grid;
      grid-template-columns: 1fr;
      gap: $space-6;

      @include responsive(normal) {
        grid-template-columns: repeat(2, 1fr);
      }

      .hearth-text {
        width: 90%;
        font-size: 7.2rem;
        font-family: "garamond-premier-pro-display", serif;
        display: flex;
        align-items: flex-end;

        .hearth-text-custom {
          margin-bottom: 0 !important;

          .text-content {
            font-size: 2rem !important;

            @include responsive(tablet) {
              font-size: 3.2rem !important;
            }
          }
        }
      }

      .hearth-banner {
        width: 100%;
        display: flex;
        justify-content: flex-end;

        .banner-image {
          width: 100%;

          @include responsive(mac-16) {
            width: 90%;
          }
        }
      }

      .hearth-body {
        margin: 0 auto;
        position: relative;
        z-index: 0;

        .hearth-banner {
          width: 100%;
        }

        .hearth-text {
          position: absolute;
          z-index: 1;
          bottom: 4rem;
          left: 7.7rem;
          width: 50%;
          text-indent: 8rem;
          color: $text-white;
          font-size: 3.2rem;
        }
      }
    }

    .delegator-wrapper {
      padding: 12.8rem 0 0;

      @include responsive(tablet) {
        padding: 12.8rem 0 $space-16;
      }

      .delegator-container {
        @include container-fluid;

        .delegator-title {
          font-family: "garamond-premier-pro-display", serif;
          width: 100%;
          margin-bottom: $space-12 !important;
          color: $text-black-2;

          @include responsive(normal) {
            width: 65%;
          }
        }

        .delegator-body {
          display: grid;
          grid-template-columns: 1fr;
          gap: $space-6;

          @include responsive(normal) {
            grid-template-columns: 1fr 0.8fr;
          }

          .item-delegator {
            width: 100%;
            display: flex;
            align-items: flex-end;

            .item-delegator__text {
              margin-bottom: 0 !important;

              .text-content {
                font-size: 2rem !important;
                width: calc(100% - 7.7rem);

                @include responsive(tablet) {
                  font-size: 3.2rem !important;
                }
              }
            }

            .delegator-img {
              width: calc(100% - 7.7rem);
              // aspect-ratio: 4/3;
              margin-left: auto;
            }
          }
        }
      }
    }

    .exchange-wrapper {
      padding: 12.8rem 0;

      .exchange-container {
        @include container-fluid;

        .exchange-title {
          // font-size: 4.8rem;
          font-family: "garamond-premier-pro-display", serif;
          width: 65%;
          margin-bottom: $space-8 !important;
          color: $text-black-2;

          @include responsive(tablet) {
            margin-bottom: $space-24 !important;
          }

          // @include responsive(normal) {
          //   font-size: 7.2rem;
          // }
        }

        .exchange-body {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: $space-3;

          @include responsive(tablet) {
            gap: $space-6;
          }

          @include responsive(mac-13) {
            margin-left: 18rem;
            width: calc(100% - 26rem);
            grid-template-columns: repeat(3, 1fr);
          }

          @include responsive(mac-16) {
            grid-template-columns: repeat(4, 1fr);
          }

          .item-exchange {
            width: 100%;
            background-color: $bg-white;
            aspect-ratio: 1/1;
            padding: $space-6;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            cursor: pointer;
            transition: 0.3s all;
            border: 1px solid transparent;

            &:hover {
              border-color: $divider-black;
            }

            .item-logo {
              width: 4rem;
              margin-bottom: $space-6;

              @include responsive(tablet) {
                width: 7.5rem;
              }
            }

            .item-name {
              @include responsive(tablet) {
                font-size: 3.2rem;
              }
            }
          }
        }
      }
    }

    .footer-page {
      background-image: url(../../assets/images/home-screen/banner-home.png);
    }
  }
}
