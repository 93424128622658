@import "styles/main.scss";

.victionProjectWrapper {
  padding-top: 12.8rem;

  @include responsive(small) {
    padding-top: 20.8rem;
  }

  :global {
    .defi-banner-wrapper {
      @include container-fluid;
      margin-bottom: 12.8rem !important;

      .defi-banner-head {
        display: flex;
        margin-bottom: $space-10;

        @include responsive(tablet) {
          margin-bottom: $space-16;
        }

        .head-title {
          width: 100%;
          // font-size: 5.6rem;
          font-family: "garamond-premier-pro-display", serif;

          @include responsive(tablet) {
            // font-size: 8rem;
          }

          @include responsive(mac-13) {
            // font-size: 9.6rem;
            width: 75rem;
          }
        }

        .head-link {
          flex-direction: column;
          justify-content: flex-end;
          align-items: center;
          width: 45%;
          display: none;

          @include responsive(normal) {
            display: flex;
          }

          .item-link {
            // font-size: 2.8rem;
            margin-bottom: $space-6;
            width: max-content;
            text-transform: uppercase;

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }

      .defi-banner-img {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        z-index: 0;
        width: 100%;

        @include responsive(small) {
          width: calc(100% - 18rem);
          align-items: center;
          flex-direction: row;
          margin-left: 18rem;
        }

        @include responsive(fhd) {
          width: calc(100% - 18rem * 2);
          margin: 0 auto;
        }

        .banner-img {
          width: 180%;
          aspect-ratio: 16/9;
          margin-bottom: $space-10;

          @include responsive(tablet) {
            width: 100%;
          }

          @include responsive(small) {
            margin: 0;
            width: 100%;
          }
        }

        .text-description {
          text-indent: 10rem;
          z-index: 1;
          bottom: $space-12;
          left: 7.7rem;
          // font-size: 2rem;

          @include responsive(tablet) {
            // font-size: 2.4rem;
          }

          @include responsive(small) {
            // font-size: 3.2rem;
            width: 45%;
            color: white;
            position: absolute;
          }
        }
      }
    }

    .project-list {
      @include container-fluid;
      margin-bottom: 12.8rem !important;

      .project-title {
        // font-size: 7.2rem;
        font-family: "garamond-premier-pro-display", serif;
        margin-bottom: 7.2rem !important;
        color: $text-black-2;
      }

      .project-body {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: $space-3;
        margin-bottom: $space-12 !important;

        @include responsive(tablet) {
          gap: $space-6;
        }

        @include responsive(mac-13) {
          grid-template-columns: repeat(3, 1fr);
        }

        @include responsive(mac-16) {
          width: calc(100% - 18rem);
          margin-left: 18rem;
          grid-template-columns: repeat(4, 1fr);
        }

        @include responsive(fhd) {
          width: calc(100% - 18rem * 2);
          margin: 0 auto;
        }

        // .item-project {
        //   width: 100%;
        //   height: 10rem;
        //   background-color: $bg-header;
        // }
      }

      .project-description {
        margin: 0 auto;
        // width: 100%;

        .description-text {
          width: 100%;
          // font-size: 1.8rem;

          @include responsive(tablet) {
            // font-size: 2.4rem;
          }
        }
      }
    }

    .footer-page {
      background-image: url(../../assets/images/home-screen/footer-img-2.png);

      .text-content {
        &--text-2 {
          left: 2.4rem !important;

          @include responsive(tablet) {
            left: 5rem !important;
          }

          @include responsive(normal) {
            left: 10rem !important;
          }
        }

        &--text-3 {
          right: 2.4rem !important;

          @include responsive(tablet) {
            right: 5rem !important;
          }

          @include responsive(normal) {
            right: 10rem !important;
          }
        }
      }
    }
  }
}
