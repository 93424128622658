@import "styles/main.scss";

.victionWallet {
  position: relative;
  z-index: 1;
  // background-color: $bg-black;

  :global {
    .viction-wallet-wrapper {
      height: 100svh;
    }

    .box-background {
      background-image: url(../../assets/images/wallet-screen/background-wallet-mobile.png);
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 100% 100%;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 250%;
      z-index: -1;
      display: flex;
      align-items: center;
      justify-content: center;

      @include responsive(tablet) {
        opacity: 0.05;
        background-image: url(../../assets/images/wallet-screen/background-wallet.png);
      }

      @include responsive(normal) {
        height: 100%;
      }

      @include responsive(mac-16) {
        height: 100%;
      }

      .background {
        width: 100%;
        height: 100%;
        // object-fit: cover;
      }
    }

    .wallet-banner {
      @include container-fluid;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: $space-52 + 0.4rem;
      height: 100svh;

      @include responsive(mac-13) {
        height: 90rem;
      }

      @include responsive(mac-16) {
        height: 100vh;
      }

      .banner-text,
      .banner-description {
        width: 100%;

        @include responsive(mac-16) {
          width: 85%;
        }
      }

      .banner-text {
        display: flex;
        flex-direction: column;
        color: $cream-color;
        padding-top: 12.8rem;
        font-size: 2.4rem;

        @include responsive(tablet) {
          font-size: 2.8rem;
          padding-top: 20rem;
        }

        @include responsive(normal) {
          font-size: unset;
          padding-top: 0rem;
          flex-direction: row;
          align-items: flex-end;
          justify-content: space-between;
        }

        @include responsive(mac-16) {
          padding-top: 8rem;
          flex-direction: row;
          align-items: flex-end;
          justify-content: space-between;
        }

        &--title {
          font-weight: 500;
          margin-bottom: $space-8;
          font-size: 4.8rem;

          @include responsive(tablet) {
            font-size: 8rem;
          }

          @include responsive(mac-13) {
            font-size: 12.8rem;
            margin-bottom: 0;
          }
        }

        &--link {
          margin-bottom: $space-5;
          width: max-content;
          font-size: 2.4rem;

          @include responsive(tablet) {
            font-size: 2.8rem;
          }
        }
      }

      .banner-description {
        .description-custom {
          text-indent: 10rem;

          .text-content {
            font-family: "neue-haas-grotesk-display";
            color: $bg-header;
            font-size: 3.2rem;

            @include responsive(tablet) {
              font-size: 4rem;
            }

            @include responsive(normal) {
              font-size: 5.6rem;
            }

            span {
              color: $cream-color;
            }
          }
        }
      }
    }

    .section-wrapper {
      @include container-fluid;
      padding-bottom: 5.6rem !important;

      @include responsive(tablet) {
        padding-bottom: 7.2rem !important;
      }

      @include responsive(normal) {
        padding-bottom: $space-24 !important;
      }

      .wallet-section {
        // margin-bottom: $space-10;
        position: sticky;
        top: 7.4rem;
        z-index: 5;
        height: calc(100svh - 7.4rem);

        &:last-child {
          margin-bottom: 0;
        }

        @include responsive(mac-13) {
          height: calc(100vh - 7.4rem);
        }

        &--section-1 {
          background-color: $bg-white;
        }

        &--section-2 {
          background-color: $bg-header;
        }

        &--section-3 {
        }

        .wallet-section-wrapper {
          height: 100%;

          .wallet-section-container {
            height: 100%;
            overflow: hidden;

            @include responsive(normal) {
              display: flex;
            }

            &--reserve {
              flex-direction: row-reverse;
            }

            .section-content {
              width: 100%;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              // padding: $space-16 $space-16;
              padding: $space-8 $space-5;
              gap: $space-6;
              height: 27rem;

              @include responsive(tablet) {
                height: 27rem;
                padding: $space-10;
                gap: $space-8;
              }

              @include responsive(small) {
                height: auto;
              }

              @include responsive(normal) {
                width: 50%;
                padding: $space-20 $space-16;
              }

              @include responsive(mac-16) {
                padding: $space-24 $space-16;
              }

              h2 {
                font-size: 3.2rem !important;

                @include responsive(tablet) {
                  font-size: 4rem !important;
                }

                @include responsive(small) {
                  font-size: 7.2rem !important;
                  transform: translateY(-2.5rem);
                }
              }

              &--description {
                .section-description-custom {
                  margin-bottom: 0 !important;
                  text-indent: 10rem;

                  &--custom-color {
                    .text-content {
                      color: var(--color-custom);
                    }
                  }

                  .text-content {
                    font-size: 1.8rem;

                    @include responsive(tablet) {
                      font-size: 2rem;
                    }

                    @include responsive(small) {
                      font-size: 3.2rem;
                    }
                  }
                }
              }
            }

            .section-img {
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              padding-left: 0;
              position: relative;
              z-index: 0;
              padding: $space-10 0;

              @include responsive(tablet) {
                padding: $space-10 0;
              }

              @include responsive(normal) {
                padding: $space-20 0;
                width: 50%;
              }

              @include responsive(mac-16) {
                padding: $space-24 0;
              }

              .img-background {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: -1;

                &--opacity {
                  opacity: 0.05;
                }

                @include responsive(screen2k) {
                  object-fit: cover;
                }
              }

              .img-screen {
                width: 45%;

                @include responsive(tablet) {
                  width: 44%;
                }

                @include responsive(normal) {
                  transform: translateY(0.5rem);
                  height: 105%;
                  width: auto;
                }

                @include responsive(mac-16) {
                }
              }
            }
          }
        }
      }
    }

    .box-install {
      background-color: $bg-white;
      padding: 12.8rem 0;

      .box-install-container {
        @include container-fluid;

        .install-title {
          margin-bottom: $space-6 !important;
          font-size: 4.8rem !important;

          @include responsive(tablet) {
            font-size: 8rem !important;
          }

          @include responsive(normal) {
            font-size: 9.6rem !important;
          }
        }

        .install-app {
          margin-left: auto;

          @include responsive(tablet) {
            width: 50%;
          }

          .item-app-link {
            border-bottom: 1px solid rgba($color: $divider-grey, $alpha: 0.5);

            &:last-child {
              border-bottom: none;
            }

            .item-app {
              padding: $space-10 0;
              width: 100%;
              color: $text-black;
              cursor: pointer;
              transition: 0.3s all;
              display: flex;
              align-items: center;
              justify-content: space-between;
              overflow: hidden;

              @include responsive(normal) {
                color: $bg-header;

                &:hover {
                  color: $text-black !important;

                  .icon-arrow {
                    opacity: 1 !important;
                    transform: translateX(0) !important;
                  }
                }
              }

              h3 {
                font-size: 3.2rem !important;

                @include responsive(tablet) {
                  font-size: 4rem !important;
                }

                @include responsive(normal) {
                  font-size: 5.6rem !important;
                }
              }

              .icon-arrow {
                opacity: 0;
                transition: 0.3s all;
                font-size: 4rem;
                transform: translateX(5rem);
              }
            }
          }
        }
      }
    }
  }
}
