@import "styles/main.scss";

.ecosystemWrapper {
  padding-top: 20.8rem;

  :global {
    .ecosystem-container {
      .ecosystem-info {
        @include container-fluid;
        margin-bottom: 12.8rem !important;

        .ecosystem-info__title {
          // font-size: 8rem;
          font-family: "garamond-premier-pro-display", serif;
          margin-bottom: $space-8 !important;

          @include responsive(tablet) {
            margin-bottom: $space-12 !important;
          }
        }

        .ecosystem-info__description {
          display: flex;
          align-items: flex-start;
          flex-direction: column;
          gap: $space-10;

          @include responsive(normal) {
            gap: 0;
            align-items: flex-end;
            flex-direction: row;
          }

          .description-item {
            &--text {
              width: 100%;

              @include responsive(normal) {
                width: 52%;
              }
            }

            &--link {
              width: 48%;
              transform: translateX(10rem);

              @include responsive(normal) {
                transform: translateX(18rem);
              }

              .text-description {
                width: max-content;
                text-transform: uppercase;
              }
            }

            .description-custom {
              margin-bottom: 0 !important;

              .text-content {
                font-size: 3.2rem;
              }
            }
          }
        }
      }

      .ecosystem-body-wrapper {
        @include container-fluid;

        .ecosystem-body {
          width: 100%;
          margin: 0 auto;
          padding-bottom: 12.8rem;

          @include responsive(mac-16) {
            width: calc(100% - 18rem * 2);
          }

          .ecosystem-filter-wrapper {
            align-items: center;

            @include responsive(tablet) {
              justify-content: center;
            }

            .ecosystem-filter {
              width: 100%;
              align-items: center;
              justify-content: flex-start;
              margin-bottom: $space-8;
              gap: $space-6;
              position: relative;
              z-index: 0;
              overflow: auto;
              display: none;
              color: $text-black-2;

              &::-webkit-scrollbar {
                display: none;
              }

              @include responsive(tablet) {
                margin-bottom: 7.2rem;
                display: flex;
              }

              .item-filter {
                padding: 0 $space-2;
                // padding-top: 0;
                text-transform: uppercase;
                opacity: 0.5;
                transition: 0.3s all;
                position: relative;
                z-index: 0;
                white-space: nowrap;
                // border-bottom: 1px solid transparent;

                &:hover {
                  opacity: 1;
                }

                &--active {
                  opacity: 1;
                  pointer-events: none;
                  // border-bottom-color: $divider-black;
                }
              }
            }

            .filter-dropdown-wrapper {
              margin-bottom: $space-10;
              display: block;

              @include responsive(tablet) {
                display: none;
              }

              .dropdown-container {
                display: flex;
                align-items: flex-start;
                gap: $space-3;
                text-transform: uppercase;
                font-size: 2rem;

                .dropdown-wrapper {
                  display: flex;
                  align-items: center;
                  position: relative;
                  z-index: 0;
                  border-bottom: 1px solid $divider-black;

                  .text-dropdown {
                    max-width: 13rem;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                  }

                  .filter-dropdown {
                    position: absolute;
                    top: 4rem;
                    left: -$space-5;
                    min-width: 18rem;
                    max-height: 0rem;
                    overflow-y: auto;
                    transition: 0.3s max-height;
                    background-color: $bg-white;

                    &--show {
                      border: 1px solid $divider-black;
                      max-height: 50rem;
                    }

                    .filter-content {
                      height: 100%;
                      width: 100%;
                      padding: $space-3 0;
                      padding-left: $space-5;

                      .item-dropdown {
                        padding: $space-4 0;
                        position: relative;
                        width: max-content;
                        text-transform: capitalize;

                        &::after {
                          content: "";
                          position: absolute;
                          bottom: 1rem;
                          left: 0;
                          width: 0%;
                          height: 1px;
                          background-color: $divider-black;
                          transition: 0.3s all;
                        }

                        &--active {
                          &::after {
                            width: 100%;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          .ecosystem-body__project {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: $space-3;
            margin-bottom: $space-12;

            @include responsive(tablet) {
              gap: $space-6;
              grid-template-columns: repeat(3, 1fr);
            }

            @include responsive(normal) {
              grid-template-columns: repeat(4, 1fr);
            }

            .item-project {
              width: 100%;
              aspect-ratio: 1/1;
              background-color: $bg-white;
              display: flex;
              flex-direction: column;
              justify-content: space-between;

              .banner-image {
                width: 7.2rem;
              }

              .item-project__title {
                font-size: 3.2rem;
                padding-bottom: $space-3;
                padding-left: $space-12;
              }
            }

            .item-custom {
              .item-project {
                .icon-logo {
                  width: 9.6rem;
                }
              }
            }
          }

          .ecosystem-body__description {
          }
        }
      }

      .footer-page {
        .text-content {
          &--text-2 {
            left: 2.4rem;

            @include responsive(normal) {
              left: 18rem;
            }
          }

          &--text-3 {
            right: 2.4rem;

            @include responsive(normal) {
              right: 18rem;
            }
          }
        }
      }
    }
  }
}
