@import "styles/main.scss";

.masternode-wrapper {
  width: 100%;
  background-color: $bg-black;
  padding: 12.8rem 0;
  color: $text-white;

  .masternode-container {
    @include container-fluid;
    color: $text-white-2;

    .masternode-title {
      width: 100%;
      // font-size: 4.8rem;
      margin-bottom: $space-12 !important;
      font-family: "garamond-premier-pro-display", serif;

      @include responsive(tablet) {
        // font-size: 6.4rem;
        width: 80%;
      }

      @include responsive(small) {
        // width: 50%;
      }

      @include responsive(normal) {
        width: 80rem;
      }
    }

    .masternode-body {
      display: grid;
      grid-template-columns: 1fr;
      gap: $space-20 $space-6;
      width: 100%;

      @include responsive(normal) {
        margin-left: 18rem;
        width: calc(100% - 18rem);
        grid-template-columns: repeat(2, 1fr);
      }

      .item-node {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @include responsive(normal) {
          width: calc(100% - 7.7rem);

          &--vertical {
            flex-direction: row;
            grid-column: 1 / span 2;

            .item-node__head,
            .item-node__img {
              width: calc(50% - (7.7rem + 2.4rem) / 2) !important;
              // width: calc(50% - 7.7rem) !important;
            }
          }
        }

        // @include responsive(fhd) {
        //   width: 100%;

        //   &--vertical {
        //     .item-node__head,
        //     .item-node__img {
        //       width: 50% !important;
        //     }
        //   }
        // }

        .item-node__head {
          &--hide {
            display: none;
          }

          .head-title {
            padding-bottom: $space-6;
            border-bottom: 1px solid $divider;
            margin-bottom: $space-8;
            text-transform: uppercase;
          }

          .head-description {
            // font-size: 2rem;
            margin-bottom: $space-6;
            text-indent: 8rem;

            @include responsive(tablet) {
              margin-bottom: $space-12;
              // font-size: 3.2rem;
            }
          }
        }

        .item-node__img,
        .img-node {
          width: 100%;
          aspect-ratio: 16/10;
          // aspect-ratio: 9/6;
        }

        .item-node__img {
          &--hide {
            display: none;
          }
        }
      }
    }
  }
}
