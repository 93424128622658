@import "styles/main.scss";

.item-event {
  display: grid;
  grid-template-columns: 1fr;
  gap: $space-6;
  margin-bottom: 12.8rem;
  padding-bottom: 12.8rem;
  border-bottom: 1px solid $divider-black-2;
  color: $text-white-2;
  width: 100%;

  @include responsive(normal) {
    grid-template-columns: repeat(2, 1fr);
  }

  &--recent {
    border-bottom-color: $divider-grey;
    color: $text-black-2;
  }

  &:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: 0;
  }

  .item-upcoming__image {
    width: 100%;

    .banner-image {
      width: 100%;

      @include responsive(normal) {
        width: 95%;
        aspect-ratio: 1/1;
      }
    }
  }

  .item-upcoming__info {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: $space-12;

    @include responsive(normal) {
      gap: 0;
      min-height: 50rem;
    }

    .info-title {
      font-size: 4.8rem;
      margin-bottom: $space-6;
    }

    .info-description {
      text-indent: 5rem;

      @include responsive(normal) {
        width: 80rem;
      }
    }

    .info-text {
      padding-left: 5rem;

      .info-text-time {
        margin-bottom: $space-1;
      }

      .list-action {
        margin-top: $space-10;
        display: flex;
        align-items: center;
        gap: $space-6;
        flex-direction: column;

        @include responsive(normal) {
          flex-direction: row;
        }

        .link-action {
          width: 100%;

          @include responsive(normal) {
            width: 45%;
          }

          .text-link {
            width: max-content;
          }
        }
      }
    }
  }
}
