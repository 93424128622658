@import "styles/main.scss";

.header-wrapper {
  width: 100%;
  position: fixed;
  top: 0;
  padding: $space-3 0;
  z-index: 888;
  transition: 0.2s all;
  color: $text-black-2;

  &--change-background {
    background-color: $bg-white;
    box-shadow: 0px 5px 10px rgba($bg-black, 0.2);
  }

  &--sub-open {
    background-color: $bg-header;
  }

  .header-container {
    @include container-fluid;

    @include responsive(normal) {
      min-height: 5rem;
    }

    .header-menu-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      min-height: 5rem;
      z-index: 0;
      display: none;

      @include responsive(normal) {
        display: flex;
      }

      .header-menus {
        display: flex;
        align-items: center;
        gap: $space-6;
        width: 45%;

        &--right {
          flex-direction: row-reverse;
        }

        .menu-item {
          width: calc(100% / 3);

          &--right {
            width: calc(100% / 3);
          }

          &--text-change {
            color: $cream-color;
          }

          &--menu-home {
            color: $text-black-2;
          }

          .text-menu {
            width: max-content;
            cursor: pointer;
            transition: 0.3s all;
            position: relative;
            text-transform: uppercase;
            font-size: 1.6rem;

            &::after {
              content: "";
              position: absolute;
              width: 100%;
              transform: scaleX(0);
              height: 1px;
              bottom: 0;
              left: 0;
              background-color: $divider-black;
              transform-origin: bottom right;
              transition: transform 0.25s ease-out;
            }

            &:hover {
              &::after {
                transform: scaleX(1);
                transform-origin: bottom left;
              }
            }
          }
        }
      }

      .icon-logo {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        font-size: 6rem;
        color: $text-black-2;
        display: none;

        @include responsive(tablet) {
          display: block;
        }

        &--text-change {
          color: $cream-color;
        }

        &--menu-home {
          color: $text-black-2;
        }
      }

      .icon-close {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
        font-size: 2.4rem;
        cursor: pointer;
        transition: 0.3s all;

        &:hover {
          color: $cream-color;
        }
      }
    }

    .header-submenu {
      width: 100%;
      max-height: 0rem;
      display: flex;
      justify-content: space-between;
      overflow: hidden;
      transition: 0.4s all;

      &--show {
        max-height: 30rem;
      }

      .submenu-item {
        width: 45%;
        display: flex;
        gap: $space-6;

        &--right {
          flex-direction: row-reverse;
        }

        .menu-sub {
          width: calc(100% / 3);

          &:last-child {
            margin-bottom: 0;
          }

          .item-sub {
            margin-bottom: $space-2;
            font-size: 1.8rem;

            &--hide {
              display: none;
            }

            &--coming {
              pointer-events: none;
            }

            .text-hover {
              width: max-content;
            }
          }
        }
      }
    }

    .header-mobile {
      align-items: center;
      justify-content: space-between;
      font-size: 3.2rem;
      display: flex;
      position: relative;
      z-index: 2;

      @include responsive(tablet) {
        font-size: 4rem;
      }

      @include responsive(normal) {
        display: none;
      }

      .icon-logo-mobile,
      .icon-menu {
        &--text-change {
          color: $cream-color;
        }

        &--menu-home {
          color: $text-black-2;
        }
      }
    }
  }

  .header-mobile-wrapper {
    position: absolute;
    top: 0;
    left: 100%;
    width: 100%;
    height: 101svh;
    background-color: $bg-header;
    padding-top: $space-16 + $space-12;
    transition: 0.3s all;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 201vh;
      background-color: $bg-header;
      z-index: -1;
    }

    &--show {
      left: 0;
    }

    @include responsive(normal) {
      display: none;
    }

    .header-tablet-container {
      @include container-fluid;
      height: calc(100% - $space-16 + $space-12);
      overflow: auto;

      .menu-list {
        display: flex;
        justify-content: space-between;
        padding-bottom: $space-8;
        margin-bottom: $space-8;
        border-bottom: 1px solid $divider-grey-2;

        &--mobile {
          flex-direction: column;
          padding-bottom: $space-6;
          margin-bottom: $space-6;

          &:last-child {
            border-bottom: none;
            padding-bottom: 0;
            margin-bottom: 0;
          }
        }

        &:last-child {
          border-bottom: none;
        }

        .item-menus {
          width: calc(100% / 3 - 4.8rem);

          &--mobile {
            width: 100%;
            padding-bottom: $space-6;
            margin-bottom: $space-6;
            border-bottom: 1px solid $divider-grey-2;

            &:last-child {
              border-bottom: none;
              padding-bottom: 0;
              margin-bottom: 0;
            }
          }

          &--hide {
            display: none;
          }

          .item-menus__title {
            margin-bottom: $space-3;
            text-transform: uppercase;
          }

          .item-menus__list {
            margin-bottom: $space-3;

            .menu-text {
              margin-bottom: $space-2;

              &--hide {
                display: none;
              }

              &:last-child {
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }
  }
}
