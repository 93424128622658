h1 {
  font-size: 5.6rem !important;
  font-weight: 400;
  margin: 0 !important;

  @include responsive(tablet) {
    font-size: 8rem !important;
  }

  @include responsive(normal) {
    font-size: 9.6rem !important;
  }
}

h2 {
  font-size: 4.8rem !important;
  font-weight: 400;
  margin: 0 !important;

  @include responsive(tablet) {
    font-size: 6.4rem !important;
  }

  @include responsive(normal) {
    font-size: 7.2rem !important;
  }
}

h3 {
  font-size: 3.2rem !important;
  font-weight: 400;
  margin: 0 !important;

  @include responsive(tablet) {
    font-size: 4.8rem !important;
  }

  @include responsive(normal) {
    font-size: 5.6rem !important;
  }
}

.text-lg {
  font-size: 2rem !important;

  @include responsive(tablet) {
    font-size: 2.8rem !important;
  }

  @include responsive(normal) {
    font-size: 3.2rem !important;
  }
}

.text-base {
  font-size: 2rem !important;

  @include responsive(tablet) {
    font-size: 2.4rem !important;
  }

  @include responsive(normal) {
    font-size: 2.8rem !important;
  }
}

.text-sm {
  font-size: 1.8rem !important;

  @include responsive(tablet) {
    font-size: 2rem !important;
  }

  @include responsive(normal) {
    font-size: 2.4rem !important;
  }
}
