@import "styles/main.scss";

.wwcWrapper {
  background-color: $bg-white;
  color: #272727;
  overflow: hidden;

  @include responsive(normal) {
    overflow: unset;
  }

  :global {
    .wwc-container {
      min-height: 100vh;

      h1 {
        font-family: "Viction";
        font-size: 4.8rem !important;

        @include responsive(normal) {
          font-size: 8.4rem !important;
        }
      }

      .wwc-banner {
        position: relative;
        z-index: 0;

        @include responsive(normal) {
          position: sticky;
          top: 0;
          z-index: 0;
          min-height: 120rem;
        }

        @include responsive(mac-16) {
          min-height: 140vh;
        }

        .img-banner {
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
          width: 200%;
          z-index: -1;

          @include responsive(normal) {
            z-index: -1;
            width: 100%;
          }
        }

        .wwc-banner__text {
          @include container-2;
          display: flex;
          align-items: center;
          flex-direction: column;
          // justify-content: center;
          padding-top: 10vh !important;
          height: 65rem;

          @include responsive(normal) {
            padding-top: 20vh !important;
            height: 100vh;
          }

          .text-title {
            text-align: center;
          }

          .text-description {
            width: 80%;
            text-align: center;
            margin: 0 auto;
            font-size: 2rem;
            margin: $space-5 0 $space-10;

            @include responsive(normal) {
              font-size: 2.4rem;
              width: 45rem;
            }
          }

          .button-actions {
            display: flex;
            align-items: center;
            flex-direction: column;
            gap: $space-3;

            @include responsive(normal) {
              flex-direction: row;
            }

            .btn {
              padding: $space-4 $space-5;
              cursor: pointer;
              border-radius: $radius;
              width: 25rem;
              display: flex;
              justify-content: center;
              gap: $space-2;
              font-weight: bold;
              font-size: 1.6rem;

              @include responsive(normal) {
                width: unset;
              }

              &--btn-1 {
                background-color: $bg-black;
                color: $bg-white;
              }

              &--btn-2 {
                background-color: $bg-white;
              }
            }
          }
        }
      }

      .wwc-content-wrapper {
        position: relative;
        z-index: 1;
        background-color: $bg-white;
        padding-bottom: 20rem;

        .pattern-up {
          position: absolute;
          width: 100%;
          z-index: 0;
          top: -2vh;
          left: 50%;
          transform: translateX(-50%);

          @include responsive(normal) {
            top: -5.5rem;
          }

          @include responsive(mac-16) {
            top: -7.2rem;
          }
        }

        .wwc-connect-dot {
          min-height: 100vh;
          padding: 12rem 0 $space-10;

          @include responsive(normal) {
            padding: 14rem 0;
          }

          .connect-dot-container {
            @include container-2;

            .dot-text {
              text-align: center;

              .dot-text__sub-title {
                font-size: 1.6rem;
                color: $text-black-3;
                width: 55%;
                margin: 0 auto;
                margin-top: $space-3;

                @include responsive(normal) {
                  width: unset;
                  margin: unset;
                  font-size: 2.4rem;
                }
              }
            }
          }

          .img-connect {
            width: 100%;
            position: relative;
            z-index: 0;
            margin: $space-20 0;

            // &::after {
            //   content: "";
            //   position: absolute;
            //   z-index: -1;
            //   top: 0;
            //   left: 0;
            //   height: 100%;
            //   width: 30%;
            //   background: linear-gradient(to right, $bg-white 50%, rgba($bg-white, 0));
            //   z-index: 1;
            // }

            // &::before {
            //   content: "";
            //   position: absolute;
            //   z-index: -1;
            //   top: 0;
            //   right: 0;
            //   height: 100%;
            //   width: 30%;
            //   background: linear-gradient(to left, $bg-white 50%, rgba($bg-white, 0));
            //   z-index: 1;
            // }

            @include responsive(normal) {
              @include container;
            }

            .connect-bg {
              width: 100%;
              transform: scale(1.5);

              @include responsive(normal) {
                transform: scale(0.9);
              }
            }
          }

          .text-dot-description {
            margin: 0 auto;
            text-align: center;
            font-size: 2rem;
            padding: 0 1.6rem;
            color: #272727;
            width: 95%;
            line-height: 1.3;

            @include responsive(normal) {
              line-height: unset;
              font-size: 2.4rem;
              width: 68rem;
              padding: 0;
            }
          }

          .icon-box {
            display: flex;
            margin: $space-10 0;
            justify-content: center;

            @include responsive(normal) {
              margin: $space-20 0;
            }

            .icon-block {
              font-size: 3rem;
            }
          }

          .description-text {
            display: flex;
            align-items: center;
            flex-direction: column;

            .description-title {
              font-weight: bold;
              font-size: 2.4rem;
              text-align: center;
              margin-bottom: $space-5;

              @include responsive(normal) {
                font-size: 2.8rem;
                margin-bottom: 0;
              }
            }

            .description-sub {
              font-size: 2rem;
              text-transform: uppercase;
              margin-top: $space-2;
            }
          }
        }

        .one-stop {
          min-height: 100vh;
          padding: $space-20 0;
          background-color: $text-white-2;

          @include responsive(normal) {
            padding: 14rem 0;
          }

          .one-stop-container {
            @include container-2;

            .title-one,
            .sub-title-one {
              text-align: center;
            }

            .title-one {
              span {
                font-family: "neue-haas-grotesk-display";
              }
            }

            .sub-title-one {
              margin-top: $space-2;
              color: $text-black-2;
              font-size: 2rem;

              @include responsive(normal) {
                margin-top: $space-2;
                font-size: 2.4rem;
              }
            }

            .one-stop-content {
              margin-top: $space-10;
              display: grid;
              grid-template-columns: 1fr;
              gap: $space-10;

              @include responsive(normal) {
                grid-template-columns: 0.55fr 1fr;
              }

              .content-text {
                grid-row: 2;

                @include responsive(normal) {
                  grid-row: unset;
                }

                .content-item {
                  margin-bottom: $space-12;

                  .icon-content {
                    width: 5rem;
                    margin-bottom: $space-2;

                    .icon-wwc {
                      width: 100%;
                    }
                  }

                  .text-content {
                    font-size: 2rem;

                    @include responsive(normal) {
                      font-size: 2.8rem;
                    }
                  }
                }

                .line {
                  height: 1px;
                  width: 100%;
                  background-color: $text-grey-4;
                  margin-bottom: $space-12;
                }

                .text-description {
                  font-weight: bold;
                  font-size: 2.4rem;
                  text-align: center;
                  width: 70%;
                  margin: 0 auto;

                  @include responsive(normal) {
                    margin: unset;
                    font-size: 2.8rem;
                    text-align: unset;
                  }
                }
              }

              .content-img {
                width: 100%;

                .img-one-stop {
                  width: 100%;
                  height: 100%;
                }
              }
            }
          }
        }

        .liberal {
          padding: $space-20 0;
          background-color: $bg-black;
          color: $cream-color;
          min-height: 100vh;

          @include responsive(normal) {
            padding: 14rem 0;
          }

          .liberal-container {
            @include container-2;

            .liberal-text-container {
              text-align: center;

              .liberal-title-sub {
                margin-top: $space-5;
                font-size: 2rem;
                color: #d0cccb;

                @include responsive(normal) {
                  font-size: 2.4rem;
                  margin-top: $space-3;
                }
              }
            }

            .bg-img {
              width: 100%;

              @include responsive(normal) {
                margin-top: 5.6rem;
              }
            }

            .liberal-description {
              text-align: center;
              font-size: 2rem;
              margin: 0 auto;
              width: 70%;
              color: #d0cccb;

              @include responsive(normal) {
                width: unset;
                font-size: 2.4rem;
                margin-top: 5.6rem;
              }
            }
          }
        }

        .scale-beyond {
          padding: $space-20 0;
          position: sticky;
          top: 0;

          @include responsive(normal) {
            min-height: 100vh;
            padding: 14rem 0;
          }

          .scale-beyond-container {
            @include container-2;

            .beyond-text {
              text-align: center;
              margin-bottom: 7.2rem;

              &--sub-title {
                font-size: 1.6rem;
                margin: 0 auto;
                margin-top: $space-5;
                color: $text-black-2;
                width: 80%;

                @include responsive(normal) {
                  font-size: 2.4rem;
                  width: 55rem;
                }
              }
            }

            .beyond-img {
              display: grid;
              grid-template-columns: 1fr;
              gap: $space-6;

              @include responsive(normal) {
                grid-template-columns: repeat(2, 1fr);
              }

              .item-beyond {
                width: 100%;

                .img-beyond {
                  width: 100%;

                  &--img-2 {
                    margin-bottom: $space-5;
                  }
                }
              }
            }
          }
        }
      }

      .connect-create {
        // min-height: 100vh;
        padding: $space-20 0 0;
        position: relative;
        z-index: 2;
        margin-top: -13rem;

        @include responsive(normal) {
          padding: 0;
          min-height: 120vh;
        }

        .bg-footer {
          width: 100%;
          margin-bottom: -2rem;
        }

        .connect-create-container {
          @include container-2;
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: space-between;
          width: 100%;

          @include responsive(normal) {
            align-items: flex-end;
            flex-direction: row;
            position: absolute;
            top: 14rem;
            left: 50%;
            transform: translateX(-50%);
          }

          .connect-title {
            text-align: center;
            margin-bottom: $space-5 !important;

            @include responsive(normal) {
              margin-bottom: 0 !important;
              text-align: unset;
            }
          }

          .head-link {
            width: 100%;

            @include responsive(normal) {
              width: unset;
            }

            .item-link {
              width: max-content;
              margin: 0 auto;
              margin-bottom: $space-5;
              font-size: 2rem !important;
              text-transform: uppercase;

              @include responsive(normal) {
                margin: unset;
                margin-bottom: $space-5;
              }
            }
          }
        }
      }
    }
  }
}
